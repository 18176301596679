.createRequestUM {
  .footerUM {
    .footerContainer {
      padding: $sd-spacing-xs $sd-spacing-sm $sd-spacing-sm $sd-spacing-sm;
      .caption {
        color: $text-color-captions;
        padding-bottom: $sd-spacing-sm;
      }
      .total {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #6c6c6c;
        height: 34px;
        min-width: 34px;
        border-radius: 6px;
        padding: 5px;
        .text {
          margin-left: 8px;
        }
      }
      .total:hover {
        background: #d3f0ee;
        cursor: pointer;
      }
    }
    hr {
      border: none;
      border-top: 1px solid #eee;
    }
    .price {
      font-family: inherit;
      font-weight: 600;
      font-size: 18px;
      color: #56738f;
    }
  }
  .content {
    .alertUM {
      button {
        text-align: right;
      }
    }

    .provider {
      margin: 8px 16px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .providerGroup {
        width: 100%;
        display: flex;
        svg {
          align-self: center;
        }
        .providerInfo {
          display: flex;
          flex-direction: column;
          padding: 4px 8px;
          margin-left: -8px;
          gap: $sd-spacing-xs;
          border-radius: 4px;
          cursor: pointer;
          &:hover {
            background-color: #e5f9f8;
          }
          &.disabled {
            cursor: no-drop;
            background-color: unset;
          }
          .providerInfoItem {
            display: flex;
            align-items: center;
            .avatarUM {
              margin-right: 6px;
            }
            img {
              margin-left: 8px;
            }
            .providerDetail {
              .name {
                display: flex;
                .primaryInfo {
                  margin-left: 16px;
                  font-weight: 600;
                }
              }
              .type {
                font-size: 10px;
                color: #6c6c6c;
              }
            }
            .seenStatus {
              margin-left: 8px;
            }
          }
        }
      }
      svg {
        margin-right: 20px;
      }
    }

    .requestedAddress {
      .clinicIcon {
        width: 20px;
        height: 20px;
        path {
          fill: #979797;
        }
      }

      .redIcon {
        path {
          fill: #ff1f4a;
        }
      }
    }

    .addressToPlace {
      display: flex;
      flex-direction: column;

      .requestedAddress {
        margin: 16px 16px 4px 16px;
      }

      .inputButtonUM.requestedAddress {
        .text {
          max-width: 88%;
        }
      }
    }

    .placeToAddress {
      display: flex;
      flex-direction: column-reverse;

      .inputButtonUM.destinationAddress {
        .text {
          flex-grow: 0.4 !important;
        }
      }

      .destination {
        margin: 16px 24px 4px 16px !important;
      }

      .requestedAddress {
        margin: 0 16px 4px 16px !important;
      }

      .destinationType {
        .icon {
          width: 28px;
          text-align: center;
        }
      }

      .requestedAddress {
        svg {
          path {
            fill: #ff5c8e;
          }
        }
      }
    }

    .ambulanceAddresses {
      position: relative;

      .swapVertical {
        position: absolute;
        right: 0;
        margin: 24px 14px 0 0;
        align-items: center;
        cursor: pointer;
      }
      .requestedAddress {
        > .icon {
          width: 24px;
          text-align: center;
        }
      }
      .destination {
        display: grid;
        align-items: center;
        grid-template-columns: 50% 50%;
        margin: 0 $sd-spacing-md $sd-spacing-xxs $sd-spacing-sm;
        gap: $sd-spacing-xs;
        .destinationAddress {
          .text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 70%;
          }
        }
        .destinationType {
          text-transform: capitalize;
          svg {
            path {
              fill: #ff5c8e;
            }
          }
          .list {
            width: 250px;
            .listItem {
              .content {
                max-width: 175px;
              }
              .info {
                color: $text-color-captions;
                font-size: 10px;
                font-weight: normal;
                text-transform: capitalize;
                text-align: right;
                min-width: 25px;
                .price {
                  font-size: 10px;
                  font-weight: normal;
                  color: $sd-green-primary;
                }
              }
            }
          }
        }
      }
      hr {
        position: absolute;
        top: 17px;
        left: 25px;
        width: 1px;
        height: 15px;
        border-left: 1px dashed #979797;
      }
    }
    .payer {
      margin: 0 $sd-spacing-sm $sd-spacing-xxs $sd-spacing-sm;
    }
    .requestedAddress,
    .paymentMethod {
      margin: $sd-spacing-sm $sd-spacing-sm $sd-spacing-xxs $sd-spacing-sm;
      text-transform: capitalize;
      .corporatePaymentMethod {
        .tagUM {
          font-size: 10px;
          height: 19px;
          background-color: #1065e4;
          color: white;
          cursor: default;
          margin-left: -8px;
          margin-right: 8px;
          text-transform: uppercase;
        }
      }
      span {
        text-transform: none;
      }
    }

    .specialtySelect {
      .MuiSelect-select {
        padding: 0;
        padding-right: 20px;
      }
      .MuiSvgIcon-root {
        color: #126ef8;
      }
      .MuiSvgIcon-root.Mui-disabled {
        color: unset;
      }
      .MuiOutlinedInput-notchedOutline {
        border-width: 0;
      }
      .Mui-disabled {
        cursor: not-allowed;
      }
    }

    .containerCheckBoxUM {
      margin: 0 $sd-spacing-sm $sd-spacing-sm 45px;
    }
    .tabsUM {
      margin-top: $sd-spacing-sm;
    }

    hr.addressSeparator {
      border: none;
      border-top: 1px solid #eee;
      margin-left: 60px;
    }
    .scheduleStart {
      margin: $sd-spacing-sm $sd-spacing-sm $sd-spacing-xxs $sd-spacing-sm;
      display: flex;
      align-items: center;
      gap: $sd-spacing-xs;
      .linkGray {
        margin-left: $sd-spacing-xs;
      }
      .buttonUM.primary {
        font-size: inherit;
        min-width: unset;
      }
    }
    .patientContainer {
      display: flex;
      gap: $sd-spacing-xxs;
      align-items: center;
      margin: $sd-spacing-xs $sd-spacing-sm;
      .icon {
        svg {
          margin-top: 3px;
        }
      }
      .patient {
        flex-grow: 1;
      }
    }
    .symptom {
      .tagUM {
        margin: 2px;
      }
      > .icon {
        svg {
          transform: rotate(90deg);
          path:first-child {
            fill: #979797;
          }
        }
      }
    }
    .notes,
    .patientCondition,
    .addonItems,
    .symptom,
    .case {
      margin: 0 $sd-spacing-sm $sd-spacing-xs $sd-spacing-sm;
      text-transform: capitalize;

      .coveredTag {
        display: flex;
        align-items: center;
        font-size: 10px;
        font-weight: 700;
        color: #1065e4;
        position: sticky;
        right: 32px;
        svg {
          margin-right: 4px;
        }
      }
    }

    .addonItems {
      .text {
        justify-content: start;
        gap: $sd-spacing-xs;
        display: block;
        .tagInfoUM {
          margin: $sd-spacing-xxs;
          .subtitle {
            .price {
              font-size: $font-size-jarvis-xs;
              color: $sd-green-primary;
            }
          }
        }
      }
    }
  }
}
